// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_A1dbM{display:flex;flex-direction:column;height:100%;min-width:100%;overflow:auto}.controls_KQwUl{align-items:center;display:flex}.control_E3d1K{cursor:pointer;display:flex;margin-right:1rem;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_E3d1K:hover{transform:scale(1.15)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_A1dbM",
	"controls": "controls_KQwUl",
	"control": "control_E3d1K"
};
module.exports = ___CSS_LOADER_EXPORT___;
